import { RESET_STATE } from '@/helpers/ResetState'
import fillDataAccordingModel from '@/helpers/fillDataAccordingModel'
import createState, { createStudent } from '@/store/client/theContract/state'
import { omit } from 'lodash'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  // Сброс полей при смене типа обучения на "Заочное"
  resetStateOfExtramuralEducation(state) {
    state.contract.grade = null
    state.contract.kinship = null

    state.contract.client.address = null
    state.contract.client.passport = null
    state.contract.client.citizenship = null
    state.contract.client.moscowRegistration = null

    state.contract.student.school = null
    state.contract.student.stream = null
    state.contract.student.enrollmentStatus = null
    state.contract.student.grade = null

    state.contract.student.phone = ''
    state.contract.student.sex = null
    state.contract.student.snils = null
    state.contract.student.health = {}
    state.contract.student.health.hasRestriction = false
    state.contract.student.health.hasDisability = false
    state.contract.student.health.hasChronicDisease = false
    state.contract.student.address = null
    state.contract.student.passport = null
    state.contract.student.placeOfBirth = ''
    state.contract.student.citizenship = null
    state.contract.student.medicalPolicy = null
    state.contract.student.familyCategory = null
    state.contract.student.birthCertificate = null
    state.contract.student.moscowRegistration = null
    state.contract.hasToProvideCertificateOfMedicalGroup = false
    state.contract.certificateOfMedicalGroup = null
  },

  // Сброс полей при смене типа обучения на "Семейное"
  resetStateOfFamilyEducation(state) {
    state.contract.school = null
    state.contract.region = null
    state.contract.academicYearPreset = null

    state.contract.grade = null
    state.contract.student.health = {}
    state.contract.paymentType = null

    state.contract.student.health.hasRestriction = false
    state.contract.student.health.hasDisability = false
    state.contract.student.health.hasChronicDisease = false
  },

  // UI

  setEducationTypes(state, payload) {
    state.educationTypes = payload
  },

  setFamilyEducationGrades(state, payload) {
    state.familyEducationGrades = payload
  },

  setSchools(state, payload) {
    state.schools = payload
  },

  setIsSchools3rdParty(state, payload) {
    state.isSchools3rdParty = payload
  },

  setKinshipDegrees(state, payload) {
    state.kinshipDegrees = payload
  },

  setCitizenships(state, payload) {
    state.citizenships = payload
  },

  setMoscowRegistrationTypes(state, payload) {
    state.moscowRegistrationTypes = payload
  },

  setMoscowRegistrationConfirmDocTypes(state, payload) {
    state.moscowRegistrationConfirmDocTypes = payload
  },

  setCountries(state, payload) {
    state.countries = payload
  },

  setSexes(state, payload) {
    state.sexes = payload
  },

  setFamilyCategories(state, payload) {
    state.familyCategories = payload
  },

  // События

  setDraftData(state, payload) {
    state.contract = payload
  },

  setRequestData(state, payload) {
    state.contract = payload
  },

  setContractData(state, payload) {
    state.contract = payload
  },

  setContractSettings(state, payload) {
    state.contractSettings = payload
  },

  addContractSettings(state, payload) {
    state.allContractSettings.push(payload)
  },

  clearContractSettings(state) {
    state.contractSettings = null
  },

  // Заполнение полей студента при выборе в селекте "Выбор существующего ученика"
  setContractStudent(state, payload) {
    state.contract.student = fillDataAccordingModel(payload, createStudent())
  },

  // Шаги и состояния шагов при создании черновика заявки

  setSteps(state, payload) {
    if (!state.contract.steps) {
      state.contract.steps = []
    }

    state.contract.steps = payload
  },

  setActiveStep(state, { i, value }) {
    state.contract.steps[i].isActive = value
  },

  setCompleteStep(state, { i, value }) {
    state.contract.steps[i].isComplete = value
  },

  setValidStatus(state, { key, value }) {
    state.validStatus[key] = value
  },

  setRegistrationCity(state, payload) {
    state.contract.region = payload
  },

  // Данные договора

  setEducationType(state, payload) {
    state.contract.educationType = payload
  },

  setEducationTypeId(state, payload) {
    state.educationTypeId = payload
  },

  setGrade(state, payload) {
    state.contract.grade = payload
  },

  setSchool(state, payload) {
    state.contract.school = payload
  },

  setEnrollmentContractSubmissionTypes(state, payload) {
    state.submissionTypes = payload
  },

  setSubmissionType(state, payload) {
    state.contract.submissionType = payload
  },

  // Данные клиента

  setKinshipDegree(state, payload) {
    if (!state.contract.kinship) {
      state.contract.kinship = {}
    }

    state.contract.kinship.kinshipDegree = payload
  },

  setKinshipConfirmDocName(state, payload) {
    if (!state.contract.kinship) {
      state.contract.kinship = {}
    }

    state.contract.kinship.confirmDocName = payload
  },

  setKinshipConfirmDocFile(state, payload) {
    if (!state.contract.kinship) {
      state.contract.kinship = {}
    }

    state.contract.kinship.confirmDocFile = payload
  },

  setClientAddressPermanent(state, payload) {
    if (!state.contract.client.address) {
      state.contract.client.address = {}
    }

    state.contract.client.address.permanent = payload
  },

  setClientAddressResidence(state, payload) {
    if (!state.contract.client.address) {
      state.contract.client.address = {}
    }

    state.contract.client.address.residence = payload
  },

  setClientAddressTemporary(state, payload) {
    if (!state.contract.client.address) {
      state.contract.client.address = {}
    }

    state.contract.client.address.temporary = payload
  },

  setClientAddressPermanentIndex(state, payload) {
    if (!state.contract.client.address) {
      state.contract.client.address = {}
    }

    state.contract.client.address.permanentIndex = payload
  },

  setClientAddressResidenceIndex(state, payload) {
    if (!state.contract.client.address) {
      state.contract.client.address = {}
    }

    state.contract.client.address.residenceIndex = payload
  },

  setClientAddressTemporaryIndex(state, payload) {
    if (!state.contract.client.address) {
      state.contract.client.address = {}
    }

    state.contract.client.address.temporaryIndex = payload
  },

  setClientAddressResidenceMatchesPermanent(state, payload) {
    if (!state.contract.client.address) {
      state.contract.client.address = {}
    }

    state.contract.client.address.residenceMatchesPermanent = payload
  },

  setClientAddressResidenceMatchesTemporary(state, payload) {
    if (!state.contract.client.address) {
      state.contract.client.address = {}
    }

    state.contract.client.address.residenceMatchesTemporary = payload
  },

  setClientFirstName(state, payload) {
    state.contract.client.firstName = payload
  },

  setClientLastName(state, payload) {
    state.contract.client.lastName = payload
  },

  setClientMiddleName(state, payload) {
    state.contract.client.middleName = payload
  },

  setClientEmail(state, payload) {
    state.contract.client.email = payload
  },

  setClientPhone(state, payload) {
    state.contract.client.newPhone = payload
  },

  setClientPassportNumber(state, payload) {
    if (!state.contract.client.passport) {
      state.contract.client.passport = {}
    }

    state.contract.client.passport.number = payload
  },

  setClientPassportSeries(state, payload) {
    if (!state.contract.client.passport) {
      state.contract.client.passport = {}
    }

    state.contract.client.passport.series = payload
  },

  setClientPassportCountry(state, payload) {
    if (!state.contract.client.passport) {
      state.contract.client.passport = {}
    }

    if (!state.contract.client.passport.country) {
      state.contract.client.passport.country = {}
    }

    state.contract.client.passport.country = payload
  },

  setClientPassportIssuedBy(state, payload) {
    if (!state.contract.client.passport) {
      state.contract.client.passport = {}
    }

    state.contract.client.passport.issuedBy = payload
  },

  setClientPassportIssuedDate(state, payload) {
    if (!state.contract.client.passport) {
      state.contract.client.passport = {}
    }

    state.contract.client.passport.issueDate = payload
  },

  setClientPassportValidUntil(state, payload) {
    if (!state.contract.client.passport) {
      state.contract.client.passport = {}
    }

    state.contract.client.passport.validUntil = payload
  },

  setClientPassportMainPageFile(state, payload) {
    if (!state.contract.client.passport) {
      state.contract.client.passport = {}
    }

    state.contract.client.passport.mainPageFile = payload
  },

  setClientPassportRegistrationPageFile(state, payload) {
    if (!state.contract.client.passport) {
      state.contract.client.passport = {}
    }

    state.contract.client.passport.registrationPageFile = payload
  },

  setClientPassportDepartmentCode(state, payload) {
    if (!state.contract.client.passport) {
      state.contract.client.passport = {}
    }

    state.contract.client.passport.departmentCode = payload
  },

  setClientPassportTranslationFile(state, payload) {
    if (!state.contract.client.passport) {
      state.contract.client.passport = {}
    }

    state.contract.client.passport.translationFile = payload
  },

  setClientPassportConfirmingDocFile(state, payload) {
    if (!state.contract.client.passport) {
      state.contract.client.passport = {}
    }

    state.contract.client.passport.confirmingDocFile = payload
  },

  setClientCitizenship(state, payload) {
    state.contract.client.citizenship = payload
  },

  setClientMoscowRegistrationType(state, payload) {
    if (!state.contract.client.moscowRegistration) {
      state.contract.client.moscowRegistration = {}
    }

    state.contract.client.moscowRegistration.type = payload
  },

  setClientMoscowRegistrationEndDate(state, payload) {
    if (!state.contract.client.moscowRegistration) {
      state.contract.client.moscowRegistration = {}
    }

    state.contract.client.moscowRegistration.endDate = payload
  },

  setClientMoscowRegistrationStartDate(state, payload) {
    if (!state.contract.client.moscowRegistration) {
      state.contract.client.moscowRegistration = {}
    }

    state.contract.client.moscowRegistration.startDate = payload
  },

  setClientMoscowRegistrationConfirmDocName(state, payload) {
    if (!state.contract.client.moscowRegistration) {
      state.contract.client.moscowRegistration = {}
    }

    state.contract.client.moscowRegistration.confirmDocName = payload
  },

  setClientMoscowRegistrationConfirmDocType(state, payload) {
    if (!state.contract.client.moscowRegistration) {
      state.contract.client.moscowRegistration = {}
    }

    state.contract.client.moscowRegistration.id = payload
  },

  setClientMoscowRegistrationConfirmDocFile(state, payload) {
    if (!state.contract.client.moscowRegistration) {
      state.contract.client.moscowRegistration = {}
    }

    state.contract.client.moscowRegistration.confirmDocFile = payload
  },

  setClientMoscowRegistrationConfirmDocNumber(state, payload) {
    if (!state.contract.client.moscowRegistration) {
      state.contract.client.moscowRegistration = {}
    }

    state.contract.client.moscowRegistration.confirmDocNumber = payload
  },

  setClientMoscowRegistrationConfirmDocIssuedBy(state, payload) {
    if (!state.contract.client.moscowRegistration) {
      state.contract.client.moscowRegistration = {}
    }

    state.contract.client.moscowRegistration.confirmDocIssuedBy = payload
  },

  setClientMoscowRegistrationConfirmDocIssuedDate(state, payload) {
    if (!state.contract.client.moscowRegistration) {
      state.contract.client.moscowRegistration = {}
    }

    state.contract.client.moscowRegistration.confirmDocIssueDate = payload
  },

  setClientAgreedWithPolitics(state, payload) {
    state.contract.agreedWithPolitics = payload
  },

  setClientAgreedWithPrivacyPolicyAndAgreement(state, payload) {
    state.contract.agreedWithPrivacyPolicyAndAgreement = payload
  },

  setClientAgreedWithProcessingPDExtramural(state, payload) {
    state.contract.agreedWithProcessingPDExtramural = payload
  },

  setClientAgreedWithProcessingPDFamily(state, payload) {
    state.contract.agreedWithProcessingPDFamily = payload
  },

  setClientAgreedWithRefundPolicy(state, payload) {
    state.contract.agreedWithRefundPolicy = payload
  },

  setFamilyEducationOfferForSchool(state, payload) {
    state.familyEducationOfferDoc = payload
  },

  setClientSnilsFile(state, payload) {
    if (!state.contract.client.snils) {
      state.contract.client.snils = {}
    }

    state.contract.client.snils.file = payload
  },

  setClientSnilsNumber(state, payload) {
    if (!state.contract.client.snils) {
      state.contract.client.snils = {}
    }

    state.contract.client.snils.number = payload
  },

  setClientSnilsRegistrationDate(state, payload) {
    if (!state.contract.client.snils) {
      state.contract.client.snils = {}
    }

    state.contract.client.snils.registrationDate = payload
  },

  // Данные ученика

  setStudentFillDataType(state, payload) {
    state.contract.studentFillDataType = payload
  },

  setExistingStudent(state, payload) {
    state.contract.existingStudent = payload
  },

  setStudentSex(state, payload) {
    state.contract.student.sex = payload
  },

  setStudentEmail(state, payload) {
    state.contract.student.email = payload
  },

  setStudentPhone(state, payload) {
    state.contract.student.phone = payload
  },

  setStudentStream(state, payload) {
    state.contract.stream = payload
  },

  setStudentSnilsFile(state, payload) {
    if (!state.contract.student.snils) {
      state.contract.student.snils = {}
    }

    state.contract.student.snils.file = payload
  },

  setStudentCertificateOfMedicalGroup(state, payload) {
    state.contract.student.certificateOfMedicalGroupFile = payload
  },

  setHasToProvideCertificateOfMedicalGroup(state, payload) {
    state.contract.hasToProvideCertificateOfMedicalGroup = payload
  },

  setStudentAdditionalFiles(state, payload) {
    if (!state.contract.student.additionalFiles) {
      state.contract.student.additionalFiles = []
    }

    state.contract.student.additionalFiles = payload
  },

  setStudentCertificateFile(state, payload) {
    state.contract.student.certificateFile = payload
  },

  setStudentSnilsNumber(state, payload) {
    if (!state.contract.student.snils) {
      state.contract.student.snils = {}
    }

    state.contract.student.snils.number = payload
  },

  setStudentSnilsRegistrationDate(state, payload) {
    if (!state.contract.student.snils) {
      state.contract.student.snils = {}
    }

    state.contract.student.snils.registrationDate = payload
  },

  setStudentHealthHasDisability(state, payload) {
    if (!state.contract.student.health) {
      state.contract.student.health = {}
    }

    state.contract.student.health.hasDisability = payload
  },

  setStudentHealthHasRestriction(state, payload) {
    if (!state.contract.student.health) {
      state.contract.student.health = {}
    }

    state.contract.student.health.hasRestriction = payload
  },

  setStudentDisabilityDocFile(state, payload) {
    if (!state.contract.student.health) {
      state.contract.student.health = {}
    }

    state.contract.student.health.disabilityDocFile = payload
  },

  setStudentHealthHasChronicDisease(state, payload) {
    if (!state.contract.student.health) {
      state.contract.student.health = {}
    }

    state.contract.student.health.hasChronicDisease = payload
  },

  setStudentHealthChronicDiseaseInformation(state, payload) {
    if (!state.contract.student.health) {
      state.contract.student.health = {}
    }

    state.contract.student.health.chronicDiseaseInformation = payload
  },

  setStudentHealthChronicDiseaseDocFile(state, payload) {
    if (!state.contract.student.health) {
      state.contract.student.health = {}
    }

    state.contract.student.health.chronicDiseaseDocFile = payload
  },

  setStudentAddressPermanent(state, payload) {
    if (!state.contract.student.address) {
      state.contract.student.address = {}
    }

    state.contract.student.address.permanent = payload
  },

  setStudentAddressResidence(state, payload) {
    if (!state.contract.student.address) {
      state.contract.student.address = {}
    }

    state.contract.student.address.residence = payload
  },

  setStudentAddressTemporary(state, payload) {
    if (!state.contract.student.address) {
      state.contract.student.address = {}
    }

    state.contract.student.address.temporary = payload
  },

  setStudentAddressPermanentIndex(state, payload) {
    if (!state.contract.student.address) {
      state.contract.student.address = {}
    }

    state.contract.student.address.permanentIndex = payload
  },

  setStudentAddressResidenceIndex(state, payload) {
    if (!state.contract.student.address) {
      state.contract.student.address = {}
    }

    state.contract.student.address.residenceIndex = payload
  },

  setStudentAddressTemporaryIndex(state, payload) {
    if (!state.contract.student.address) {
      state.contract.student.address = {}
    }

    state.contract.student.address.temporaryIndex = payload
  },

  setStudentAddressResidenceMatchesPermanent(state, payload) {
    if (!state.contract.student.address) {
      state.contract.student.address = {}
    }

    state.contract.student.address.residenceMatchesPermanent = payload
  },

  setStudentAddressResidenceMatchesTemporary(state, payload) {
    if (!state.contract.student.address) {
      state.contract.student.address = {}
    }

    state.contract.student.address.residenceMatchesTemporary = payload
  },

  setStudentNoPassport(state) {
    state.contract.student.passport = null
  },

  setStudentHasPassport(state) {
    state.contract.student.passport = {
      number: '',
      series: '',
      country: null,
      issuedBy: '',
      issueDate: '',
      validUntil: '',
      mainPageFile: null,
      registrationPageFile: null,
      departmentCode: '',
      translationFile: null,
      confirmingDocFile: null,
    }
  },

  setStudentPassportNumber(state, payload) {
    if (!state.contract.student.passport) {
      state.contract.student.passport = {}
    }

    state.contract.student.passport.number = payload
  },

  setStudentPassportSeries(state, payload) {
    if (!state.contract.student.passport) {
      state.contract.student.passport = {}
    }

    state.contract.student.passport.series = payload
  },

  setStudentPassportCountry(state, payload) {
    if (!state.contract.student.passport) {
      state.contract.student.passport = {}
    }

    if (!state.contract.student.passport.country) {
      state.contract.student.passport.country = {}
    }

    state.contract.student.passport.country = payload
  },

  setStudentPassportIssuedBy(state, payload) {
    if (!state.contract.student.passport) {
      state.contract.student.passport = {}
    }

    state.contract.student.passport.issuedBy = payload
  },

  setStudentPassportIssuedDate(state, payload) {
    if (!state.contract.student.passport) {
      state.contract.student.passport = {}
    }

    state.contract.student.passport.issueDate = payload
  },

  setStudentPassportValidUntil(state, payload) {
    if (!state.contract.student.passport) {
      state.contract.student.passport = {}
    }

    state.contract.student.passport.validUntil = payload
  },

  setStudentPassportMainPageFile(state, payload) {
    if (!state.contract.student.passport) {
      state.contract.student.passport = {}
    }

    state.contract.student.passport.mainPageFile = payload
  },

  setStudentPassportRegistrationPageFile(state, payload) {
    if (!state.contract.student.passport) {
      state.contract.student.passport = {}
    }

    state.contract.student.passport.registrationPageFile = payload
  },

  setStudentPassportDepartmentCode(state, payload) {
    if (!state.contract.student.passport) {
      state.contract.student.passport = {}
    }

    state.contract.student.passport.departmentCode = payload
  },

  setStudentPassportTranslationFile(state, payload) {
    if (!state.contract.student.passport) {
      state.contract.student.passport = {}
    }

    state.contract.student.passport.translationFile = payload
  },

  setStudentPassportConfirmingDocFile(state, payload) {
    if (!state.contract.student.passport) {
      state.contract.student.passport = {}
    }

    state.contract.student.passport.confirmingDocFile = payload
  },

  setStudentId(state, payload) {
    state.contract.student = {
      ...state.contract.student,
      id: payload,
    }
  },

  unsetStudentId(state) {
    state.contract.student = omit(state.contract.student, [
      'id',
    ])
  },

  setStudentBirthDate(state, payload) {
    state.contract.student.birthDate = payload
  },

  setStudentFirstName(state, payload) {
    state.contract.student.firstName = payload
  },

  setStudentLastName(state, payload) {
    state.contract.student.lastName = payload
  },

  setStudentMiddleName(state, payload) {
    state.contract.student.middleName = payload
  },

  setStudentCitizenship(state, payload) {
    state.contract.student.citizenship = payload
  },

  deleteStudentAdditionalData(state) {
    if (!state.contract.student.health.hasDisability) {
      state.contract.student.health.disabilityDocFile = null
    }

    if (!state.contract.student.health.hasChronicDisease) {
      state.contract.student.health.chronicDiseaseInformation = ''
      state.contract.student.health.chronicDiseaseDocFile = null
    }
  },

  // TODO: Заменить на ConvertEmptyDataToDefaultValue
  deleteStudentSnils(state) {
    const { snils } = state.contract.student

    if (snils && !snils.number && !snils.registrationDate && !snils.file) {
      state.contract.student.snils = null
    }
  },

  deleteClientSnils(state) {
    const { snils } = state.contract.client

    if (snils && !snils.number && !snils.registrationDate && !snils.file) {
      state.contract.client.snils = null
    }
  },

  setStudentPlaceOfBirth(state, payload) {
    state.contract.student.placeOfBirth = payload
  },

  setStudentMedicalPolicyFile(state, payload) {
    if (!state.contract.student.medicalPolicy) {
      state.contract.student.medicalPolicy = {}
    }

    state.contract.student.medicalPolicy.file = payload
  },

  setStudentMedicalPolicyNumber(state, payload) {
    if (!state.contract.student.medicalPolicy) {
      state.contract.student.medicalPolicy = {}
    }

    state.contract.student.medicalPolicy.number = payload
  },

  setStudentIsPreferentialFamilyCategory(state, payload) {
    state.contract.student.isPreferentialFamilyCategory = payload
  },

  setStudentFamilyCategory(state, payload) {
    state.contract.student.familyCategory = payload
  },

  setStudentBirthCertificateFile(state, payload) {
    if (!state.contract.student.birthCertificate) {
      state.contract.student.birthCertificate = {}
    }

    state.contract.student.birthCertificate.file = payload
  },

  setStudentBirthCertificateTranslationFile(state, payload) {
    if (!state.contract.student.birthCertificate) {
      state.contract.student.birthCertificate = {}
    }

    state.contract.student.birthCertificate.translationFile = payload
  },

  setStudentBirthCertificateNumber(state, payload) {
    if (!state.contract.student.birthCertificate) {
      state.contract.student.birthCertificate = {}
    }

    state.contract.student.birthCertificate.number = payload
  },

  setStudentBirthCertificateSeries(state, payload) {
    if (!state.contract.student.birthCertificate) {
      state.contract.student.birthCertificate = {}
    }

    state.contract.student.birthCertificate.series = payload
  },

  setStudentBirthCertificateIssuedDate(state, payload) {
    if (!state.contract.student.birthCertificate) {
      state.contract.student.birthCertificate = {}
    }

    state.contract.student.birthCertificate.issueDate = payload
  },

  setStudentMoscowRegistrationType(state, payload) {
    if (!state.contract.student.moscowRegistration) {
      state.contract.student.moscowRegistration = {}
    }

    state.contract.student.moscowRegistration.type = payload
  },

  setStudentMoscowRegistrationEndDate(state, payload) {
    if (!state.contract.student.moscowRegistration) {
      state.contract.student.moscowRegistration = {}
    }

    state.contract.student.moscowRegistration.endDate = payload
  },

  setStudentMoscowRegistrationStartDate(state, payload) {
    if (!state.contract.student.moscowRegistration) {
      state.contract.student.moscowRegistration = {}
    }

    state.contract.student.moscowRegistration.startDate = payload
  },

  setStudentMoscowRegistrationConfirmDocName(state, payload) {
    if (!state.contract.student.moscowRegistration) {
      state.contract.student.moscowRegistration = {}
    }

    state.contract.student.moscowRegistration.confirmDocName = payload
  },

  setStudentMoscowRegistrationConfirmDocType(state, payload) {
    if (!state.contract.student.moscowRegistration) {
      state.contract.student.moscowRegistration = {}
    }

    state.contract.student.moscowRegistration.confirmDocType = payload
  },

  setStudentMoscowRegistrationConfirmDocFile(state, payload) {
    if (!state.contract.student.moscowRegistration) {
      state.contract.student.moscowRegistration = {}
    }

    state.contract.student.moscowRegistration.confirmDocFile = payload
  },

  setStudentMoscowRegistrationConfirmDocNumber(state, payload) {
    if (!state.contract.student.moscowRegistration) {
      state.contract.student.moscowRegistration = {}
    }

    state.contract.student.moscowRegistration.confirmDocNumber = payload
  },

  setStudentMoscowRegistrationConfirmDocIssuedBy(state, payload) {
    if (!state.contract.student.moscowRegistration) {
      state.contract.student.moscowRegistration = {}
    }

    state.contract.student.moscowRegistration.confirmDocIssuedBy = payload
  },

  setStudentMoscowRegistrationConfirmDocIssuedDate(state, payload) {
    if (!state.contract.student.moscowRegistration) {
      state.contract.student.moscowRegistration = {}
    }

    state.contract.student.moscowRegistration.confirmDocIssueDate = payload
  },

  setAcademicYearPresetOption(state, payload) {
    state.academicYearPresets = payload
  },

  setAcademicYearPreset(state, payload) {
    state.contract.academicYearPreset = payload
  },

  setExistingTemplates(state, payload) {
    state.existingTemplates = payload
  },

  setOfferText(state, payload) {
    state.offerText = payload
  },

  setServiceDetails(state, payload) {
    state.serviceDetails = payload
  },

  setPrice(state, payload) {
    state.price = payload
  },

  setPaymentType(state, payload) {
    state.contract.paymentType = payload
  },

  setPromocode(state, payload) {
    state.contract.promocode = payload
  },

  setWithEnlightenmentClassbook(state, payload) {
    state.contract.withEnlightenmentClassbook = payload
  },

  setPromocodeDetails(state, payload) {
    state.promocodeDetails = payload
  },

  unsetPromocodeDetails(state) {
    state.promocodeDetails = null
  },

  setInvoice(state, payload) {
    state.invoice = payload
  },

  unsetInvoice(state) {
    state.invoice = null
  },

  setCustomerReceipt(state, payload) {
    state.customerReceipt = payload
  },

  unsetCustomerReceipt(state) {
    state.customerReceipt = null
  },

  setRequestNumber(state) {
    state.requestNumber = null
  },

  setSchoolData(state, payload) {
    state.school = payload
  },
}
